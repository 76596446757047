/**
 * 网站右侧
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import NoSSR from 'react-no-ssr';
import Chip from 'Chip';
import Ad from '@ifeng-fe/ui_pc_ad';
import styles from './index.css';
import ShanklistComponents from '../../shanklistComponents/index';
import BigImageNewsComponent from '../../shanklistComponents/BigImageNewsComponent/load';

class Aside extends PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    asideView(asideData) {
        const element = [];

        for (let i = 0, j = asideData.length; i < j; i++) {
            const item = asideData[i];
            const { chip, data, component, componentOptions } = item;

            if (!(component in ShanklistComponents)) return null;

            if (chip.user && chip.user !== '') {
                element.push(
                    <Chip key={i} content={data} {...chip}>
                        {React.createElement(ShanklistComponents[component], { componentOptions, index: i, chip })}
                    </Chip>,
                );
            } else {
                element.push(
                    React.createElement(ShanklistComponents[component], {
                        key: i,
                        componentOptions,
                        content: data,
                        index: i,
                    }),
                );
            }
        }

        return element;
    }
    render() {
        const { asideData, noAd, asideAd } = this.props.content;

        return (
            <div className={styles.aside}>
                {this.asideView(asideData)}
                {noAd ? null : (
                    <NoSSR onSSR={<div className={styles.ad} />}>
                        <Ad styleName={styles.ad} content={asideAd} />
                    </NoSSR>
                )}
            </div>
        );
    }
}

export { Aside };
export default Aside;
