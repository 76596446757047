import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import NoSSR from 'react-no-ssr';
import Chip from 'Chip';
import ChipEdit from 'ChipEdit';
import transform from 'chipDataTransform';
import styles from './index.css';
import Ad from '@ifeng-fe/ui_pc_ad';
import Nav from './nav';
import HtmlRegion from '../../components/htmlRegion';
import Header from './header';
import Logo from './logo';
import Content from './content';
import Aside from './aside';
import Footer from './bottomFooter';
import ToTop from '../../components/toTop';
import AdRegion from './adRegion';
import Piaohong from './piaohong';
import Context from './context';

class Layout extends PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    constructor(...args) {
        super(...args);

        const { content } = this.props;

        this.state = {
            piaohong: content.piaohong && content.piaohong.piaohong ? content.piaohong.piaohong : false,
        };
    }

    componentDidMount() {
        // 上报首屏时间
        if (window && window.BJ_REPORT) window.BJ_REPORT.firstScreen();
    }

    setPiaohong = (value, cb) => {
        this.setState(
            {
                piaohong: value,
            },
            cb,
        );
    };

    render() {
        /**
         * 组件分发数据
         */
        const { content } = this.props;
        const headerData = {
            nav: content.nav,
            isBiz: content.isBiz,
        };
        const logoData = {
            search: content.search,
            channel: content.channel,
            noAd: content.noAd,
            guanmingAd: content.guanmingAd,
        };
        const contentData = {
            channel: content.channel,
            newsstream: content.newsstream,
            isEnd: content.isEnd,
            columnId: content.columnId,
            mainData: content.mainData,
            commonIdArray: content.commonIdArray,
            newsStream1Ad: content.newsStream1Ad,
            newsStream2Ad: content.newsStream2Ad,
            noAd: content.noAd,
            newsStream: content.newsStream, // 覆盖配置
        };
        const asideData = {
            asideData: content.asideData,
            noAd: content.noAd,
            asideAd: content.asideAd,
        };
        const footerData = {
            bottomAd: content.bottomAd,
            footer: content.footer,
            isBiz: content.isBiz,
        };
        const adData = {
            float1Ad: content.float1Ad,
            float2Ad: content.float2Ad,
            coupletAd: content.coupletAd,
            rightBottomAd: content.rightBottomAd,
            popunderAd: content.popunderAd,
            triggerLeftAd: content.triggerLeftAd,
        };
        const contextValue = {
            piaohong: this.state.piaohong,
        };

        return (
            <Context.Provider value={contextValue}>
                <ChipEdit transform={transform} />
                {/* 节日飘红 */}
                {// eslint-disable-next-line no-nested-ternary
                content.isBiz ? (
                    content.piaohong ? (
                        <Piaohong
                            columnId={content.columnId}
                            content={content.piaohong}
                            setPiaohong={this.setPiaohong}
                        />
                    ) : null
                ) : (
                    <Chip id="50030" type="struct" title="节日飘红" groupName="节日飘红" content={content.piaohong}>
                        {content.piaohong ? (
                            <Piaohong columnId={content.columnId} setPiaohong={this.setPiaohong} />
                        ) : null}
                    </Chip>
                )}
                {/* 顶部导航 */}
                <Header content={headerData} />
                {/* 顶部广告 */}
                {content.noAd ? null : (
                    <NoSSR onSSR={<div className={styles.top_ad} />}>
                        <Ad styleName={styles.top_ad} content={content.headAd} />
                    </NoSSR>
                )}
                {/* logo */}
                <Logo content={logoData} />
                {/* 导航栏 */}
                {content.topNav ? (
                    <Chip id={content.topNavId} type="struct" title="导航" groupName="导航栏 " content={content.topNav}>
                        <Nav />
                    </Chip>
                ) : null}
                {/* main */}
                <div className={`${styles.main} clearfix`}>
                    {/* 左侧新闻 */}
                    <Content content={contentData} />
                    {/* 右侧aside */}
                    <Aside content={asideData} />
                </div>
                <div>
                    {content.passAd ? (
                        <NoSSR onSSR={<div className={styles.passAd} />}>
                            <Ad styleName={styles.passAd} content={content.passAd} />
                        </NoSSR>
                    ) : null}
                </div>
                {/* 底部合作链接 */}
                {content.cooperation ? (
                    <Chip
                        id={content.footerId}
                        type="static"
                        title="底部合作链接"
                        groupName="底部"
                        content={content.cooperation}>
                        <HtmlRegion />
                    </Chip>
                ) : null}
                {/* 底部广告位 */}
                <div>
                    {content.noAd ? null : (
                        <NoSSR onSSR={<div className={styles.footer_ad} />}>
                            <Ad styleName={styles.footer_ad} content={content.footerAd} />
                        </NoSSR>
                    )}
                </div>
                {/* 底部 */}
                <NoSSR>
                    <Footer content={footerData} />
                </NoSSR>
                {/* 返回顶部 */}
                <ToTop />
                {/* 广告位 */}
                {content.noAd ? null : <AdRegion content={adData} />}
            </Context.Provider>
        );
    }
}

export default Layout;
