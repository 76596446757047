/**
 * 左侧内容
 */
import Chip from 'Chip';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { NewsStream } from '@ifeng-fe/news-stream';
import '@ifeng-fe/news-stream/lib/NewsStream/style/news-stream-global.css';
import styles from './index.css';
import ShanklistComponents from '../../shanklistComponents/index';

const getType = function getType(type) {
    const types = {
        recommendFragment: 'recommend',
        staticFragment: 'static',
        structuredFragment: 'struct',
    };

    return types[type] ? types[type] : type;
};

class Content extends PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    // 渲染
    componentsView(mainData) {
        const { noAd } = this.props.content;

        return mainData.map((item, index) => {
            const { chip, data, component, componentOptions } = item;

            if (!(component in ShanklistComponents)) return null;

            if (chip.user && chip.user !== '') {
                // 重写编辑碎片的配置
                if (componentOptions.key) chip.id = componentOptions.key;
                if (componentOptions.type) chip.type = getType(componentOptions.type);

                return (
                    <Chip key={index} content={data} {...chip}>
                        {React.createElement(ShanklistComponents[component], { componentOptions, noAd })}
                    </Chip>
                );
            } else {
                return React.createElement(ShanklistComponents[component], {
                    key: index,
                    componentOptions,
                    content: data,
                    noAd,
                });
            }
        });
    }
    // 广告数组
    adArr() {
        const { newsStream1Ad, newsStream2Ad } = this.props.content;

        const ad = [];

        if (newsStream1Ad) ad.push(newsStream1Ad);
        if (newsStream2Ad) ad.push(newsStream2Ad);

        return ad;
    }
    render() {
        const { content } = this.props;
        const { newsstream, isEnd, columnId, mainData, commonIdArray, newsStream } = content;
        const newsStreamProps = {
            content: newsstream,
            columnId: newsStream ? newsStream.key : columnId,
            isEnd,
            isDisplaySource: true,
            repeatedID: commonIdArray,
            ad: this.adArr(),
        };

        if (newsStream) newsStreamProps.dataTypeName = newsStream.type;

        return (
            <div className={styles.content}>
                {/* 组件 */}
                {this.componentsView(mainData)}
                <NewsStream {...newsStreamProps} />
            </div>
        );
    }
}

export { Content };
export default Content;
