/* 置顶组件 */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { formatUrl } from '@ifeng-fe/public_method';
import NoSSR from 'react-no-ssr';
import styles from './index.css';
import { rel as relText } from '../../../utils/rel';
import { classnames } from '../../../utils/classnames';
import { Event } from '@ifeng-fe/ui_base';
import { handleAd } from '../../../utils/handleAd';

class TopNewsComponent extends PureComponent {
    static propTypes = {
        content: PropTypes.array,
        componentOptions: PropTypes.object,
    };

    constructor(...argv) {
        super(...argv);

        const {
            content,
            componentOptions: { extendData, length },
        } = this.props;
        const formatData = this.formatData(content, extendData, length);

        this.state = {
            data: formatData,
        };
    }

    componentDidMount() {
        const {
            componentOptions: { ad },
        } = this.props;
        let adContent = null;

        if (ad && typeof window === 'object') {
            adContent = window['allData'][ad];
        }
        // // 插入广告
        if (adContent) this.getAd(adContent);
    }
    event = new Event();
    // 获取广告
    async getAd(ad) {
        const len = this.state.data.length;
        const callback = await handleAd(ad);

        callback(ad.data, this.event, this.insert);

        this.event.trigger('init', { len, index: 0 });
    }
    insert = (insertArr, replaceArr, adFn) => {
        console.log('Ten Recommend Callback.', insertArr, replaceArr, adFn);

        if (adFn && typeof adFn === 'function') {
            const { data } = this.state;

            const infoData = [...data];
            const refs = [];

            insertArr.forEach(item => {
                const ref = React.createRef();

                refs.push({ index: item, ref });

                infoData.splice(item, 0, { type: 'ad', ref });
            });

            replaceArr.forEach(item => {
                const ref = React.createRef();

                refs.push({ index: item, ref });

                infoData.splice(item, 1, { type: 'ad', ref });
            });

            this.setState(
                {
                    data: infoData,
                },
                () => {
                    const dom = {};

                    for (const ref of refs) {
                        dom[ref.index] = ref.ref.current;
                    }

                    adFn(dom);
                },
            );
        }
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.content !== nextProps.content) {
            const {
                content,
                componentOptions: { extendData, length },
            } = nextProps;

            this.setState({ data: this.formatData(content, extendData, length) });
        }
    }

    // 数据处理
    formatData(data = [], extendData = [], length = 3) {
        let dLen = length;

        // 先计算extendData内的数据
        const extendDataResult = [];

        for (const item of extendData) {
            dLen = length - item.componentOptions.length;

            for (const item2 of item.data) {
                extendDataResult.push(item2);
            }
        }

        // 主数据至少为1条
        if (dLen <= 0) dLen = 1;

        const mainData = [];

        for (let i = 0, j = dLen > data.length ? data.length : dLen; i < j; i++) {
            const item = data[i];

            mainData.push({
                id: item.id,
                thumbnail: item.thumbnail,
                url: item.notFormatUrl ? item.url : formatUrl(item.url),
                title: item.title,
                children: item.children,
                marquee: item.marquee,
            });
        }

        return mainData.concat(extendDataResult).slice(0, length);
    }

    // 渲染同行
    childrenView(children) {
        return children.map((item, index) => (
            <a className={styles.children} key={index} href={item.url} title={item.title} target="_blank" rel={relText}>
                {item.title}
            </a>
        ));
    }

    // 渲染单个的推荐位
    liView(recommend) {
        return recommend.map((item, index) => {
            if (item) {
                let newItem = item;
                let children = item.children;

                if (item.marquee && item.children && item.children.length > 0) {
                    newItem = [item].concat(item.children)[Math.floor(Math.random() * (item.children.length + 1))];
                    children = null;
                }

                return item.type === 'ad' ? (
                    <h3
                        ref={item.ref}
                        key={index}
                        className={classnames(index === 0 ? styles.first_title : styles.second_title, 'top_news_title')}
                    />
                ) : (
                    <h3
                        key={index}
                        className={classnames(
                            index === 0 ? styles.first_title : styles.second_title,
                            'top_news_title',
                        )}>
                        <a href={newItem.url} title={newItem.title} target="_blank" rel={relText}>
                            {newItem.title}
                        </a>
                        {children && children.length > 0 && this.childrenView(children)}
                    </h3>
                );
            } else {
                return null;
            }
        });
    }

    // 渲染推荐位组
    topNewsView(data = [], groupLength = 1, itemLength = 3) {
        const element = [];

        for (let i = 0, j = data.length, k = 1; k <= groupLength; i += itemLength, k += 1) {
            let recommend = null;
            let isBreak = false;

            if (k === groupLength) {
                if (i < j) recommend = data.slice(i, j);
            } else if (i + itemLength > j) {
                recommend = data.slice(i, j);
                isBreak = true;
            } else {
                recommend = data.slice(i, i + itemLength);
            }

            if (recommend) {
                element.push(
                    <div key={k} className={styles.topNews}>
                        {this.liView(recommend)}
                    </div>,
                );
            }

            if (isBreak) break;
        }

        return element;
    }

    elementRender() {
        const { data } = this.state;
        const { componentOptions } = this.props;

        const groupLength =
            componentOptions && componentOptions.groupLength !== undefined ? componentOptions.groupLength : 1;
        const itemLength =
            componentOptions && componentOptions.itemLength !== undefined ? componentOptions.itemLength : 3;

        if (data.length <= 0) return null; // 没有数据且无法编辑返回null

        return this.topNewsView(data, groupLength, itemLength);
    }

    render() {
        if (!this.props || !this.props.content) return null; // 没有content返回null

        return (
            <div>
                <NoSSR>{this.elementRender()}</NoSSR>
            </div>
        );
    }
}

export default errorBoundary(TopNewsComponent);
