import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import { rel as relText } from '../../../utils/rel';
import { classnames } from '../../../utils/classnames';
import Context from '../context';

class Nav extends PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };

    // 渲染导航
    navView(list = []) {
        return list.map((item, index) => (
            <li key={index}>
                <a href={item.url} target="_blank" rel={relText}>
                    {item.title}
                </a>
            </li>
        ));
    }

    navRender = value => {
        const { content } = this.props;

        return (
            <div
                className={`${classnames(styles.nav_box, 'nav_box')}${value.piaohong ? ` ${styles.nav_piaohong}` : ''}`}
                id="navigation">
                <ul className={classnames(styles.nav, 'clearfix', 'nav')}>{this.navView(content)}</ul>
            </div>
        );
    };

    render() {
        const { content } = this.props;

        if (content) {
            return <Context.Consumer>{this.navRender}</Context.Consumer>;
        } else {
            return null;
        }
    }
}

export { Nav };
export default Nav;
