import React from 'react';
import PropTypes from 'prop-types';
import NoSSR from 'react-no-ssr';
import styles from './index.css';
import Chip from 'Chip';
import UserInfo from '@ifeng-fe/ui_pc_userInfo';
import Nav from '../../../components/nav/index';

/**
 * 定义 Header 组件
 */
class Header extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const { content } = this.props;

        return (
            <div className={styles.main_nav} id="main_nav">
                {content.isBiz ? (
                    <Nav limit={18} content={content.nav} />
                ) : (
                    <Chip id={20002} type="struct" title="导航" groupName="头部" content={content.nav}>
                        <Nav limit={18} />
                    </Chip>
                )}

                <div className={styles.userinfo}>
                    <NoSSR>
                        <UserInfo />
                    </NoSSR>
                </div>
            </div>
        );
    }
}

export { Header };
export default Header;
