import SliderComponent from './SliderComponent';
import TopNewsComponent from './TopNewsComponent';
import NewsGroupComponent from './NewsGroupComponent';
import AsideStaticGroupComponent from './AsideStaticGroupComponent/load';
import OneDayBookSliderComponent from './OneDayBookSliderComponent/load';
import NewsStreamComponent from './NewsStreamComponent';
import AdComponent from './AdComponent';
import ChannelReadingComponent from './ChannelReadingComponent';
import TimeLineComponent from './TimeLineComponent/load';
import HeadlinePhotoNewsComponent from './HeadlinePhotoNewsComponent/load';
import BigImageNewsComponent from './BigImageNewsComponent/load';
import BigPhotoComponent from './BigPhotoComponent/load';
import AsideSliderComponent from './AsideSliderComponent/load';

export default {
    SliderComponent, // 轮播图
    AsideSliderComponent, // 侧栏轮播图
    TopNewsComponent, // 置顶新闻一大二小
    NewsGroupComponent, // 侧栏原创频道
    AsideStaticGroupComponent, // 侧边栏静态碎片组件
    OneDayBookSliderComponent, // 一日一书
    NewsStreamComponent, // 信息流
    AdComponent, // 广告
    ChannelReadingComponent, // 频道读书
    TimeLineComponent, // 区块链时间线
    HeadlinePhotoNewsComponent, // 区块链聚焦头条
    BigImageNewsComponent, // 区块链热点专题
    BigPhotoComponent, // 文创频道
};
