import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { formatUrl, formatImage } from '@ifeng-fe/public_method';
import Ad from '@ifeng-fe/ui_pc_ad';
import styles from './index.css';
import Chip from 'Chip';
import { Event } from '@ifeng-fe/ui_base';
import publicStyle from '../public/newsGroup.css';
import { handleAd } from '../../../utils/handleAd';
import { rel as relText } from '../../../utils/rel';
import { classnames } from '../../../utils/classnames';

class NewsGroupComponent extends PureComponent {
    static propTypes = {
        content: PropTypes.array,
        componentOptions: PropTypes.object,
        index: PropTypes.number,
        chip: PropTypes.object,
    };

    componentDidMount() {
        const {
            componentOptions: { feedAd },
        } = this.props;
        let adContent = null;

        if (feedAd && typeof window === 'object') {
            adContent = window['allData'][feedAd];
        }
        // // 插入广告
        if (adContent) this.getAd(adContent);
    }
    event = new Event();
    // 获取广告
    async getAd(ad) {
        const len = this.state.data.length;
        const callback = await handleAd(ad);

        callback(ad.data, this.event, this.insert);

        this.event.trigger('init', { len, index: 0 });
    }
    insert = (insertArr, replaceArr, adFn) => {
        // console.log('Ten Recommend Callback.', insertArr, replaceArr, adFn);

        if (adFn && typeof adFn === 'function') {
            const { data } = this.state;

            const infoData = [...data];
            const refs = [];

            insertArr.forEach(item => {
                const ref = React.createRef();

                refs.push({ index: item, ref });

                infoData.splice(item, 0, { type: 'ad', ref });
            });

            replaceArr.forEach(item => {
                const ref = React.createRef();

                refs.push({ index: item, ref });

                infoData.splice(item, 1, { type: 'ad', ref });
            });

            this.setState(
                {
                    data: infoData,
                },
                () => {
                    const dom = {};

                    for (const ref of refs) {
                        dom[ref.index] = ref.ref.current;
                    }

                    adFn(dom);
                },
            );
        }
    };

    constructor(...argv) {
        super(...argv);

        const { content, componentOptions } = this.props;
        const formatData = this.formatData(content, componentOptions.extendData || [], componentOptions.length || 4);

        this.state = {
            data: formatData,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.content !== nextProps.content) {
            const { content, componentOptions } = nextProps;

            this.setState({
                data: this.formatData(content, componentOptions.extendData || [], componentOptions.length || 4),
            });
        }
    }

    // 判断为视频
    isVideo = item => item.videoCount;

    // 判断为图片
    isImage = item =>
        item.thumbnail ||
        (item.thumbnails &&
            item.thumbnails !== '' &&
            Array.isArray(item.thumbnails.image) &&
            item.thumbnails.image.length > 0);

    // 获取image的url
    getImageUrl(item) {
        if ('picUrl' in item) return item.picUrl;
        else return item.url;
    }

    bigImageNewsView() {
        const {
            componentOptions: { childRecomName, childRecomType },
        } = this.props;
        let bigImageNewsData = {};
        let allBigImageNewsData = [];

        if (
            childRecomName &&
            typeof window === 'object' &&
            window['allData'][childRecomName] &&
            window['allData'][childRecomName][0]
        ) {
            allBigImageNewsData = window['allData'][childRecomName];
            bigImageNewsData = window['allData'][childRecomName][0];
        }

        if (!bigImageNewsData.title) return null;
        let img = bigImageNewsData.thumbnail ? bigImageNewsData.thumbnail : null;

        img =
            !img && bigImageNewsData.thumbnails && bigImageNewsData.thumbnails.image[0]
                ? this.getImageUrl(bigImageNewsData.thumbnails.image[0])
                : img;

        return childRecomType === 'smallImg' ? (
            <div className={`${styles.has_image} clearfix`}>
                <a
                    className={styles.image_link}
                    href={bigImageNewsData.url}
                    title={bigImageNewsData.title}
                    target="_blank"
                    rel={relText}>
                    <img
                        src={img ? formatImage(img, 98, 54) : undefined}
                        alt={bigImageNewsData.title}
                        width={98}
                        height={54}
                    />
                </a>
                <h5 className={styles.image_title}>
                    <a href={bigImageNewsData.url} title={bigImageNewsData.title} target="_blank" rel={relText}>
                        {bigImageNewsData.title}
                    </a>
                </h5>
            </div>
        ) : (
            allBigImageNewsData.map((item, index) => {
                let bigImg = item.thumbnail ? item.thumbnail : null;

                bigImg =
                    !bigImg && item.thumbnails && item.thumbnails.image[0]
                        ? this.getImageUrl(item.thumbnails.image[0])
                        : bigImg;

                return (
                    <div key={index} className={styles.bigImageNew}>
                        <a href={item.url} target="_blank" rel={relText}>
                            <img className={styles.bigImageNewImg} src={formatImage(bigImg, 301, 182)} />
                            <div className={styles.bigImageNewText}>{item.title}</div>
                        </a>
                    </div>
                );
            })
        );
    }

    // 渲染第一行图片
    firstImageRender(firstData) {
        const { thumbnail, url, title } = firstData;
        let img = thumbnail ? thumbnail : null;

        img =
            !img && firstData.thumbnails && firstData.thumbnails.image[0]
                ? this.getImageUrl(firstData.thumbnails.image[0])
                : img;

        return (
            <div className={styles.firstImageBox}>
                <a className={styles.firstImage} href={url} target="_blank" rel={relText}>
                    <img className={styles.firstImage} src={img} alt={title} />
                </a>
            </div>
        );
    }

    // 渲染列表
    newsView(list) {
        const {
            content,
            componentOptions: { extendData, allPic, noSmall }, // 所有展示为图片
        } = this.props;

        let topType = null; // 第一个的类型：video或image
        let topIndex = null; // 第一个的索引

        // 有混合数据时，把混合数据放在最上面
        if (content.length > 0 && extendData && extendData.length > 0) {
            topIndex = 0;

            if (this.isVideo(list[0])) topType = 'video';
            else if (this.isImage(list[0])) topType = 'image';
            else topType = null;
        } else {
            for (let i = 0, j = list.length; i < j; i++) {
                const item = list[i];

                if (this.isVideo(item) && topType === null) {
                    topType = 'video';
                    topIndex = i;
                } else if (this.isImage(item) && topType === null) {
                    topType = 'image';
                    topIndex = i;
                }
            }
        }

        // 排序
        if (topIndex !== null && topIndex > 0) {
            const top = list[topIndex];

            list.splice(topIndex, 1);
            list.unshift(top);
        }

        return list.map((item, index) => {
            const key = item.id;
            const url = item.notFormatUrl ? item.url : formatUrl(item.url);

            if (item.type === 'ad') {
                return <li key={key} className={styles.no_image} ref={item.ref} />;
            }

            // 渲染视频模式
            if (!noSmall && ((index === 0 && topType === 'video') || (allPic && this.isVideo(item)))) {
                const { thumbnail } = item;
                let img = thumbnail ? thumbnail : null;

                img =
                    !img && item.thumbnails && item.thumbnails.image[0]
                        ? this.getImageUrl(item.thumbnails.image[0])
                        : img;

                return (
                    <li key={key} className={`${styles.has_image} clearfix`}>
                        <a className={styles.image_link} href={url} title={item.title} target="_blank" rel={relText}>
                            <img
                                src={img ? formatImage(img, 98, 54) : undefined}
                                alt={item.title}
                                width={98}
                                height={54}
                            />
                            <div className={styles.play} />
                        </a>
                        <h5 className={styles.image_title}>
                            <a href={url} title={item.title} target="_blank" rel={relText}>
                                {item.title}
                            </a>
                        </h5>
                    </li>
                );
            }

            // 渲染图片模式
            if (!noSmall && ((index === 0 && topType === 'image') || allPic)) {
                const { thumbnail } = item;
                let img = thumbnail ? thumbnail : null;

                img =
                    !img && item.thumbnails && item.thumbnails.image[0]
                        ? this.getImageUrl(item.thumbnails.image[0])
                        : img;

                return (
                    <li key={key} className={`${styles.has_image} clearfix`}>
                        <a className={styles.image_link} href={url} title={item.title} target="_blank" rel={relText}>
                            <img
                                src={img ? formatImage(img, 98, 54) : undefined}
                                alt={item.title}
                                width={98}
                                height={54}
                            />
                        </a>
                        <h5 className={styles.image_title}>
                            <a href={url} title={item.title} target="_blank" rel={relText}>
                                {item.title}
                            </a>
                        </h5>
                    </li>
                );
            }

            // 无图
            return (
                <li key={key} className={styles.no_image}>
                    <a href={url} title={item.title} target="_blank" rel={relText}>
                        {item.title}
                    </a>
                </li>
            );
        });
    }

    // 数据处理
    formatData(data = [], extendData = [], length = 4) {
        let dLen = length;

        // 先计算extendData内的数据
        const extendDataResult = [];

        for (const item of extendData) {
            dLen = length - item.data.length;

            for (const item2 of item.data) extendDataResult.push(item2);
        }

        // 主数据至少为1条
        if (dLen <= 0) dLen = 1;

        const mainData = [];

        for (let i = 0, j = dLen > data.length ? data.length : dLen; i < j; i++) mainData.push(data[i]);

        return mainData.concat(extendDataResult).slice(0, length);
    }

    render() {
        const {
            componentOptions,
            componentOptions: { infoTitle, infoUrl, ad, firstImageLink, childRecomKey },
            index,
            chip,
        } = this.props;
        const { data } = this.state;
        const firstData = firstImageLink ? data[0] : null;
        const listData = firstImageLink ? data.slice(1) : data;

        let dataKey = null;
        let adContent = null;

        if (ad && typeof window === 'object') {
            dataKey = componentOptions.dataKey || 'allData';
            adContent = window[dataKey][ad];
        }
        const isChip = chip?.type === 'recommend' && chip?.id && chip?.title && chip?.groupName;
        const chipParams = {
            id: chip?.id,
            type: chip?.type,
            title: chip?.title,
            groupName: chip?.groupName,
        };

        return (
            <div className={publicStyle.news_group}>
                {/* 标题 */}
                <div className={`${publicStyle.news_group_title} clearfix`}>
                    <i className={classnames(publicStyle.block, 'block')} />
                    <span className={publicStyle.link_title}>{infoTitle}</span>
                    {infoUrl ? (
                        <a className={publicStyle.more} href={infoUrl} target="_blank" rel={relText}>
                            更多&gt;
                        </a>
                    ) : null}
                    {ad ? <Ad styleName={styles.ad} content={adContent} /> : null}
                </div>
                {childRecomKey ? (
                    <Chip id={childRecomKey} type="recommend" title="图文维护" groupName="凤凰网健康">
                        {this.bigImageNewsView()}
                    </Chip>
                ) : null}
                {firstImageLink ? this.firstImageRender(firstData) : null}
                {/* 新闻 */}
                {isChip ? (
                    <Chip {...chipParams}>
                        <ul className={classnames(styles.news_list, 'news_list')} id={`aside_${index}_list`}>
                            {this.newsView(listData)}
                        </ul>
                    </Chip>
                ) : (
                    <ul className={classnames(styles.news_list, 'news_list')} id={`aside_${index}_list`}>
                        {this.newsView(listData)}
                    </ul>
                )}
            </div>
        );
    }
}

export default errorBoundary(NewsGroupComponent);
