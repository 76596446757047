import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import { rel as relText } from '../../../utils/rel';

class Piaohong extends PureComponent {
    static propTypes = {
        content: PropTypes.object,
        columnId: PropTypes.string,
        setPiaohong: PropTypes.func,
    };

    constructor(...argu) {
        super(...argu);

        const { content, columnId } = this.props;
        const list = content.columnId || [];

        // columnId
        this.state = {
            piaohong: content.piaohong && list.includes(columnId),
        };
    }

    componentDidMount() {
        const { piaohong } = this.state;
        const { image } = this.props.content;

        if (piaohong) {
            const body = document.getElementsByTagName('body')[0];

            body.className += ' yuandan';
            body.style.backgroundImage = `url('${image}')`;
        }
    }

    // 飘红回调
    closePiaohongCallback() {
        const body = document.getElementsByTagName('body')[0];

        body.className = body.className.replace('yuandan', '');
        body.style.backgroundImage = null;
    }

    // 关闭飘红
    handleClosePiaohongClick = event => {
        event.stopPropagation();

        this.setState(
            {
                piaohong: false,
            },
            this.closePiaohongCallback,
        );

        this.props.setPiaohong(false);
    };

    render() {
        const { piaohong } = this.state;
        const { content } = this.props;
        const { url, closeImage, closeImageDisplay, imageHeight } = content;

        return piaohong ? (
            <div className={styles.piaohong} style={{ height: `${imageHeight}px` }}>
                <a className={styles.piaohonglink} href={url} target="_blank" rel={relText} />
                {closeImageDisplay ? (
                    <input
                        className={styles.close}
                        style={{ backgroundImage: `url('${closeImage}')` }}
                        type="button"
                        onClick={this.handleClosePiaohongClick}
                    />
                ) : null}
            </div>
        ) : null;
    }
}

export default Piaohong;
