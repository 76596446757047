/**
 * html直接插入的组件
 */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class HtmlRegion extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        content: PropTypes.string,
    };

    /**
     * 插入 Cooperation html
     */
    createHtml = () => {
        return { __html: this.props.content };
    };
    /**
     * 渲染组件
     */
    render() {
        if (this.props.content) {
            return <div className={this.props && this.props.className} dangerouslySetInnerHTML={this.createHtml()} />;
        } else {
            return null;
        }
    }
}

export { HtmlRegion };
export default HtmlRegion;
