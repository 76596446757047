import React from 'react';
import PropTypes from 'prop-types';
import Chip from 'Chip';
import styles from './index.css';
import Footer from '../../../components/footer/index';

/**
 * 定义 Footer 组件
 */
class BottomFooter extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const { footer, isBiz } = this.props.content;

        return (
            <div className={styles.footer}>
                {isBiz ? (
                    <Footer content={footer} />
                ) : (
                    <Chip id="20012" type="struct" title="底部公用版权" groupName="底部" content={footer}>
                        <Footer />
                    </Chip>
                )}
            </div>
        );
    }
}

export { BottomFooter };
export default BottomFooter;
