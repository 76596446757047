/* 类似于信息流的推荐位 */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { NewsStream } from '@ifeng-fe/news-stream';
import '@ifeng-fe/news-stream/lib/NewsStream/style/news-stream-global.css';

class NewsStreamComponent extends PureComponent {
    static propTypes = {
        content: PropTypes.array,
        componentOptions: PropTypes.object,
    };

    constructor(...argv) {
        super(...argv);

        const {
            content,
            componentOptions: { extendData, length },
        } = this.props;
        const formatData = this.formatData(content, extendData, length);

        this.state = {
            data: formatData,
        };
    }
    formatData(data = [], extendData = [], length = 3) {
        let dLen = length;

        // 先计算extendData内的数据
        const extendDataResult = [];

        for (const item of extendData) {
            dLen = length - item.data.length;

            for (const item2 of item.data) {
                extendDataResult.push(item2);
            }
        }

        // 主数据至少为1条
        if (dLen <= 0) dLen = 1;

        const mainData = [];

        for (let i = 0, j = dLen > data.length ? data.length : dLen; i < j; i++) {
            const item = data[i];

            mainData.push(item);
        }

        return mainData.concat(extendDataResult).slice(0, length);
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.content !== nextProps.content) {
            const {
                content,
                componentOptions: { extendData, length },
            } = nextProps;

            this.setState({ data: this.formatData(content, extendData, length) });
        }
    }
    render() {
        const { data } = this.state;

        return <NewsStream content={data} isEnd={true} isDisplayLoadingMore={false} />;
    }
}

export default errorBoundary(NewsStreamComponent);
