/* 频道读书组件 */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { formatUrl, formatImage } from '@ifeng-fe/public_method';
import styles from './index.css';
import publicStyle from '../public/newsGroup.css';
import { classnames } from '../../../utils/classnames';
import { rel as relText } from '../../../utils/rel';

class ChannelReadingComponent extends PureComponent {
    static propTypes = {
        content: PropTypes.array,
        componentOptions: PropTypes.object,
    };

    listView(list = []) {
        return list.map((item, index) => {
            const url = item.notFormatUrl ? item.url : formatUrl(item.url);

            return (
                <li key={index} className={index % 2 === 0 ? null : styles.ml}>
                    <a className={styles.imglink} href={url} target="_blank" rel={relText}>
                        <img src={formatImage(item.thumbnail, 124, 167)} alt={item.title} />
                    </a>
                    <p className={styles.link}>
                        <a href={url} title={item.title} target="_blank" rel={relText}>
                            {item.title}
                        </a>
                    </p>
                </li>
            );
        });
    }
    render() {
        const { content, componentOptions } = this.props;

        return (
            <div className={publicStyle.news_group}>
                {/* 标题 */}
                <div className={`${publicStyle.news_group_title} clearfix`}>
                    <i className={classnames(publicStyle.block, 'block')} />
                    <span className={publicStyle.link_title}>{componentOptions.title}</span>
                </div>
                <ul className={classnames(styles.pindaodushu, 'clearfix')}>{this.listView(content)}</ul>
            </div>
        );
    }
}

export default errorBoundary(ChannelReadingComponent);
