import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Search from '@ifeng-fe/ui_pc_search';
import { formatUrl } from '@ifeng-fe/public_method';
import Ad from '@ifeng-fe/ui_pc_ad';
import styles from './index.css';
import { classnames } from '../../../utils/classnames';
import { rel as relText } from '../../../utils/rel';

class Logo extends PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    // 显示logo还是title
    displayLogoOrTitleView(item) {
        if ('logo' in item && item.logo && item.logo !== '') {
            return <img className={styles.logo_image} src={item.logo} alt={item.title} />;
        } else {
            return <b className={styles.logo_text}>{item.title}</b>;
        }
    }

    // 显示其他导航
    otherNavRender(otherChannel = []) {
        return otherChannel.map((item, index) => {
            return (
                <span key={item.title} className={styles.logo_second_text}>
                    <span className={styles.logo_gt}>&gt;</span>
                    {item.title}
                </span>
            );
        });
    }

    render() {
        const { content } = this.props;
        const { channel = [], noAd, guanmingAd } = content;
        const [channel0, channel1, ...otherChannel] = channel;

        return (
            <div className={classnames(styles.logo_box, 'clearfix')}>
                <div className={styles.logoBox2}>
                    <a href={`//${channel0.domain}`} target="_blank" rel={relText}>
                        <img className={styles.logo_finace} src={formatUrl(channel[0].logo)} alt={channel[0].title} />
                    </a>
                    {channel.length === 1 && channel0.slogen && channel0.slogen !== '' ? (
                        <img className={styles.slogen} src={formatUrl(channel0.slogen)} />
                    ) : null}
                </div>
                {channel1 ? this.displayLogoOrTitleView(channel1) : null}
                {otherChannel && otherChannel.length > 0 ? this.otherNavRender(otherChannel) : null}
                {/* 广告 */}
                {!noAd && guanmingAd ? <Ad styleName={styles.ad} content={guanmingAd} /> : null}
                <Search content={content.search} />
            </div>
        );
    }
}

export { Logo };
export default Logo;
